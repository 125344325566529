import TwentyfourSevenOfficeLogo from 'static/gfx/integrations/24sevenoffice.svg'
import TwentyfourSevenOfficeDarkLogo from 'static/gfx/integrations/24sevenoffice-dark.svg'
import AltinnLogo from 'static/gfx/integrations/altinn.svg'
import EntraIdLogo from 'static/gfx/integrations/entra-id.svg'
import EntraIdDarkLogo from 'static/gfx/integrations/entra-id-dark.svg'
import CronaLonLogo from 'static/gfx/integrations/crona-lon.svg'
import CronaLonDarkLogo from 'static/gfx/integrations/crona-lon-dark.svg'
import FortnoxLogo from 'static/gfx/integrations/fortnox.svg'
import FortnoxDarkLogo from 'static/gfx/integrations/fortnox-dark.svg'
import NetvisorLogo from 'static/gfx/integrations/netvisor.svg'
import NetvisorDarkLogo from 'static/gfx/integrations/netvisor-dark.svg'
import NuuLogo from 'static/gfx/integrations/nuu.svg'
import NuuDarkLogo from 'static/gfx/integrations/nuu-dark.svg'
import PowerOfficeLogo from 'static/gfx/integrations/poweroffice.svg'
import PowerOfficeDarkLogo from 'static/gfx/integrations/poweroffice-dark.svg'
import SlackLogo from 'static/gfx/integrations/slack.svg'
import SlackDarkLogo from 'static/gfx/integrations/slack-dark.svg'
import TeamtailorLogo from 'static/gfx/integrations/teamtailor.svg'
import TripletexLogo from 'static/gfx/integrations/tripletex.svg'
import TripletexDarkLogo from 'static/gfx/integrations/tripletex-dark.svg'
import UnimicroLogo from 'static/gfx/integrations/unimicro.svg'
import UnimicroDarkLogo from 'static/gfx/integrations/unimicro-dark.svg'
import VismaLogo from 'static/gfx/integrations/visma.svg'
import VismaDarkLogo from 'static/gfx/integrations/visma-dark.svg'
import WebhookLogo from 'static/gfx/integrations/webhook.svg'
import WebhookDarkLogo from 'static/gfx/integrations/webhook-dark.svg'
import XledgerLogo from 'static/gfx/integrations/xledger.svg'
import XledgerDarkLogo from 'static/gfx/integrations/xledger-dark.svg'
import {
    TwentyfourSevenOfficeIcon,
    AltinnIcon,
    EntraIdIcon,
    CronaLonIcon,
    FortnoxIcon,
    NetvisorIcon,
    NuuIcon,
    PowerOfficeIcon,
    SlackIcon,
    TeamtailorIcon,
    TripletexIcon,
    UnimicroIcon,
    VismaIcon,
    WebhookIcon,
    XledgerIcon
} from 'pages/integrations/utilities/icon'

const getCommonExternalLinks = ({ tld }) => [
    {
        url: `https://www.${tld}/terms-of-service`,
        label: {
            id: 'integration_link_huma_tos',
            defaultMessage: 'Huma’s Terms of Service'
        }
    }
]

const data = {
    '24sevenoffice': ({ tld } = {}) => ({
        id: '24sevenoffice',
        logo: TwentyfourSevenOfficeLogo,
        logoDark: TwentyfourSevenOfficeDarkLogo,
        icon: TwentyfourSevenOfficeIcon,
        name: '24SevenOffice', // Yup, it's 24SevenOffice, not 24 Seven Office
        website: 'https://24sevenoffice.com/no/produkter/lonn-og-personal/lonnssystem',
        knowledgebaseUrl: `https://www.${tld}/knowledge/24sevenoffice-integration`,
        country: 'NO',
        externalLinks: [
            ...getCommonExternalLinks({ tld }),
            {
                url: 'https://24sevenoffice.com/no/terms/privacy-notice',
                label: {
                    id: 'integration_24sevenoffice_link_privacy_notice',
                    defaultMessage: '24SevenOffice’s Privacy Notice'
                }
            }
        ]
    }),
    altinn: ({ tld } = {}) => ({
        id: 'altinn',
        logo: AltinnLogo,
        icon: AltinnIcon,
        name: 'NAV / Altinn',
        website: 'https://www.altinn.no/',
        knowledgebaseUrl: `https://www.${tld}/knowledge/altinn-integration`,
        country: 'NO'
    }),
    azure: ({ tld } = {}) => ({
        id: 'azure',
        logo: EntraIdLogo,
        logoDark: EntraIdDarkLogo,
        icon: EntraIdIcon,
        name: 'Microsoft Entra ID',
        website: 'https://www.microsoft.com/en-gb/security/business/identity-access/microsoft-entra-id/',
        knowledgebaseUrl: `https://www.${tld}/knowledge/entra-id-integration`,
        externalLinks: [
            ...getCommonExternalLinks({ tld }),
            {
                url: 'https://learn.microsoft.com/en-us/entra/identity/conditional-access/terms-of-use',
                label: {
                    id: 'integration_entra_id_link_terms_of_use',
                    defaultMessage: 'Microsoft Entra ID’s terms of use'
                }
            }
        ]
    }),
    cronalon: ({ tld } = {}) => ({
        id: 'cronalon',
        logo: CronaLonLogo,
        logoDark: CronaLonDarkLogo,
        icon: CronaLonIcon,
        name: 'Crona Lön',
        website: 'https://crona.se/loneprogram',
        knowledgebaseUrl: `https://www.${tld}/knowledge/crona-lon-integration`,
        country: 'SE',
        externalLinks: [
            ...getCommonExternalLinks({ tld }),
            {
                url: 'https://crona.se/integritetspolicy',
                label: {
                    id: 'integration_cronalon_link_integrity_policy',
                    defaultMessage: 'Crona Lön’s Integrity policy'
                }
            }
        ]
    }),
    fortnox: ({ tld } = {}) => ({
        id: 'fortnox',
        logo: FortnoxLogo,
        logoDark: FortnoxDarkLogo,
        icon: FortnoxIcon,
        name: 'Fortnox',
        country: 'SE',
        website: 'https://www.fortnox.se/',
        knowledgebaseUrl: `https://www.${tld}/knowledge/fortnox-integration`,
        externalLinks: [
            ...getCommonExternalLinks({ tld }),
            {
                url: 'https://www.fortnox.se/om-fortnox/integritet-och-sakerhet/',
                label: {
                    id: 'integration_fortnox_link_privacy_policy',
                    defaultMessage: 'Fortnox’ Privacy policy'
                }
            },
            {
                url: 'https://www.fortnox.se/om-fortnox/integritet-och-sakerhet/avtal-och-villkor/',
                label: {
                    id: 'integration_fortnox_link_terms_conditions',
                    defaultMessage: 'Fortnox’ Terms and conditions'
                }
            }
        ]
    }),
    netvisor: ({ tld } = {}) => ({
        id: 'netvisor',
        logo: NetvisorLogo,
        logoDark: NetvisorDarkLogo,
        icon: NetvisorIcon,
        name: 'Netvisor',
        country: 'FI',
        website: 'https://www.netvisor.fi/',
        knowledgebaseUrl: `https://www.${tld}/knowledge/netvisor-integration`,
        externalLinks: [
            ...getCommonExternalLinks({ tld }),
            {
                url: 'https://vismasolutions.com/tietosuoja/',
                label: {
                    id: 'integration_netvisor_link_privacy_policy',
                    defaultMessage: 'Netvisor’s Privacy policy'
                }
            },
            {
                url: 'https://netvisor.fi/yhteystiedot/netvisor-kayttoehdot/',
                label: {
                    id: 'integration_netvisor_link_terms_conditions',
                    defaultMessage: 'Netvisor’s Terms and conditions'
                }
            }
        ]
    }),
    nuu: ({ tld } = {}) => ({
        id: 'nuu',
        logo: NuuLogo,
        logoDark: NuuDarkLogo,
        icon: NuuIcon,
        name: 'Nuu',
        country: 'NO',
        website: 'https://www.nuu.no/',
        knowledgebaseUrl: `https://www.${tld}/knowledge/nuu-integration`,
        externalLinks: [
            ...getCommonExternalLinks({ tld }),
            {
                url: 'https://www.nuu.no/personvern',
                label: {
                    id: 'integration_nuu_link_privacy_policy',
                    defaultMessage: 'Nuu’s Privacy policy'
                }
            }
        ]
    }),
    poweroffice: ({ tld } = {}) => ({
        id: 'poweroffice',
        logo: PowerOfficeLogo,
        logoDark: PowerOfficeDarkLogo,
        icon: PowerOfficeIcon,
        name: 'PowerOffice',
        country: 'NO',
        website: 'https://www.poweroffice.no/',
        knowledgebaseUrl: `https://www.${tld}/knowledge/poweroffice-integration`,
        externalLinks: [
            ...getCommonExternalLinks({ tld }),
            {
                url: 'https://poweroffice.no/gdpr/',
                label: {
                    id: 'integration_poweroffice_link_privacy_policy',
                    defaultMessage: 'PowerOffice’s Privacy policy'
                }
            }
        ]
    }),
    slack: ({ tld } = {}) => ({
        id: 'slack',
        logo: SlackLogo,
        logoDark: SlackDarkLogo,
        icon: SlackIcon,
        name: 'Slack',
        website: 'https://www.slack.com/',
        knowledgebaseUrl: `https://www.${tld}/knowledge/slack-integration`
    }),
    teamtailor: ({ tld } = {}) => ({
        id: 'teamtailor',
        logo: TeamtailorLogo,
        icon: TeamtailorIcon,
        name: 'Teamtailor',
        website: 'https://www.teamtailor.com/',
        marketplaceUrl: 'https://app.teamtailor.com/companies/xMzm--QADLI/marketplace/partners/379/activate',
        knowledgebaseUrl: `https://www.${tld}/knowledge/teamtailor-integration`,
        externalLinks: [
            ...getCommonExternalLinks({ tld }),
            {
                url: 'https://www.teamtailor.com/en/privacy-policy/',
                label: {
                    id: 'integration_teamtailor_link_privacy_policy',
                    defaultMessage: 'Teamtailor’s Privacy policy'
                }
            }
        ]
    }),
    tripletex: ({ tld } = {}) => ({
        id: 'tripletex',
        logo: TripletexLogo,
        logoDark: TripletexDarkLogo,
        icon: TripletexIcon,
        name: 'Tripletex',
        country: 'NO',
        website: 'https://www.tripletex.no/',
        knowledgebaseUrl: `https://www.${tld}/knowledge/tripletex-integration`,
        externalLinks: [
            ...getCommonExternalLinks({ tld }),
            {
                url: 'https://www.tripletex.no/personvernerklaering/',
                label: {
                    id: 'integration_tripletex_link_privacy_policy',
                    defaultMessage: 'Tripletex’ Privacy policy'
                }
            },
            {
                url: 'https://www.tripletex.no/databehandleravtale/',
                label: {
                    id: 'integration_tripletex_link_terms_conditions',
                    defaultMessage: 'Tripletex’ Terms and conditions'
                }
            }
        ]
    }),
    unimicro: ({ tld } = {}) => ({
        id: 'unimicro',
        logo: UnimicroLogo,
        logoDark: UnimicroDarkLogo,
        icon: UnimicroIcon,
        name: 'Unimicro',
        country: 'NO',
        website: 'https://www.unimicro.no/',
        knowledgebaseUrl: `https://www.${tld}/knowledge/unimicro-integration`,
        externalLinks: [
            ...getCommonExternalLinks({ tld }),
            {
                url: 'https://www.unimicro.no/personvern',
                label: {
                    id: 'integration_unimicro_link_privacy_policy',
                    defaultMessage: 'Unimicro’s Privacy policy'
                }
            }
        ]
    }),
    'visma.net': ({ tld } = {}) => ({
        id: 'visma.net',
        logo: VismaLogo,
        logoDark: VismaDarkLogo,
        icon: VismaIcon,
        name: 'Visma.net Payroll',
        country: 'NO',
        website: 'https://www.visma.no/lonn/lonnssystem/',
        knowledgebaseUrl: `https://www.${tld}/knowledge/visma-net-payroll-integration`,
        externalLinks: [
            ...getCommonExternalLinks({ tld }),
            {
                url: 'https://www.visma.com/privacy-statement',
                label: {
                    id: 'integration_visma_link_privacy_policy',
                    defaultMessage: 'Visma’s Privacy Statement'
                }
            },
            {
                url: 'https://www.visma.com/trust-centre/security/vasp-vcdm/terms-of-service/',
                label: {
                    id: 'integration_visma_link_terms_conditions',
                    defaultMessage: 'Visma’s Terms of Service'
                }
            }
        ]
    }),
    webhook: ({ tld } = {}) => ({
        id: 'webhook',
        logo: WebhookLogo,
        logoDark: WebhookDarkLogo,
        icon: WebhookIcon,
        name: 'Webhook',
        knowledgebaseUrl: `https://www.${tld}/knowledge/this-is-how-the-profile-webhook-work`,
        externalLinks: getCommonExternalLinks({ tld })
    }),
    xledger: ({ tld } = {}) => ({
        id: 'xledger',
        logo: XledgerLogo,
        logoDark: XledgerDarkLogo,
        icon: XledgerIcon,
        name: 'Xledger',
        country: 'NO',
        website: 'https://www.xledger.no/',
        knowledgebaseUrl: `https://www.${tld}/knowledge/xledger-integration`,
        externalLinks: [
            ...getCommonExternalLinks({ tld }),
            {
                url: 'https://xledger.com/no/personvern/',
                label: {
                    id: 'integration_xledger_link_privacy_policy',
                    defaultMessage: 'Xledger’s Privacy policy'
                }
            },
            {
                url: 'https://xledger.com/no/terms-of-use/',
                label: {
                    id: 'integration_xledger_link_terms_conditions',
                    defaultMessage: 'Xledger’s Terms and conditions'
                }
            }
        ]
    })
}

export default new Proxy(data, {
    get(target, key) {
        if(!(key in target)) {
            return () => ({
                id: key,
                name: key
            })
        }

        return Reflect.get(...arguments)
    }
})