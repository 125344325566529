import { css, keyframes, createGlobalStyle } from 'styled-components'
import { normalize } from 'styled-normalize'
import { belowTablet, belowLaptop, belowDesktop, bp, print, iOSSafari } from 'utilities/styled'
import { map } from 'utilities/object'
import colorTokens from 'static/color-tokens.json'

import ApercuLightProWoff from 'static/fonts/apercu-pro/light.woff'
import ApercuLightProWoff2 from 'static/fonts/apercu-pro/light.woff2'

import ApercuRegularProWoff from 'static/fonts/apercu-pro/regular.woff'
import ApercuRegularProWoff2 from 'static/fonts/apercu-pro/regular.woff2'

import ApercuMediumProWoff from 'static/fonts/apercu-pro/medium.woff'
import ApercuMediumProWoff2 from 'static/fonts/apercu-pro/medium.woff2'

import ApercuBoldProWoff from 'static/fonts/apercu-pro/bold.woff'
import ApercuBoldProWoff2 from 'static/fonts/apercu-pro/bold.woff2'

import RecoletaBoldWoff2 from 'static/fonts/recoleta/bold.woff2'
import RecoletaBoldWoff from 'static/fonts/recoleta/bold.woff'

export const optimizeFontRendering = css`
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
`

export const fontSerif = css`
    font-family: "Windows Chromium Flag Emoji", Recoleta, Georgia, sans-serif;
`

export const fontSans = css`
    font-family: "Windows Chromium Flag Emoji", "Apercu Pro", sans-serif;
`

export const fontMonospace = css`
    font-family: "Windows Chromium Flag Emoji", ui-monospace, "Cascadia Mono", "Segoe UI Mono", "Liberation Mono", Menlo, Monaco, Consolas, monospace;
`

const fadeIn = keyframes`
    from { opacity: 0; }
`

const fadeOut = keyframes`
    to { opacity: 0; }
`

const slideFromStart = keyframes`
    from { transform: translateX(-50px); }
`

const slideFromEnd = keyframes`
    from { transform: translateX(50px); }
`

const slideToStart = keyframes`
    to { transform: translateX(-50px); }
`

const slideToEnd = keyframes`
    to { transform: translateX(50px); }
`

const mapPalette = palette =>
    map(palette, (colors, name) => {
        if(typeof colors === 'string') {
            return `--huma-palette-${name}: ${colors};`
        }

        return map(colors, (value, shade) => {
            return `--huma-palette-${name}-${shade}: ${value};`
        }).join('\n')
    }).join('\n')

const createToken = (value, name, key, subkey) => {
    const alias = {
        constructive: 'info',
        destructive: 'error'
    }[key]

    if(!subkey) {
        return `
            --huma-color-${name}-${key}: var(--huma-palette-${value});
            ${!!alias ? `--huma-color-${name}-${alias}: var(--huma-color-${name}-${key});` : ''}
        `
    }

    return `
        --huma-color-${name}-${key}-${subkey}: var(--huma-palette-${value});
        ${!!alias ? `--huma-color-${name}-${alias}-${subkey}: var(--huma-color-${name}-${key}-${subkey});` : ''}
    `
}

const mapThemeTokens = theme =>
    map(theme, (tokens, name) =>
        map(tokens, (value, key) => {
            if(typeof value === 'string') {
                return createToken(value, name, key)
            }

            return map(value, (v, k) => createToken(v, name, key, k)).join('\n')
        }).join('\n')
    ).join('\n')

const globalStylesCss = css`
    @font-face {
        font-family: "Apercu Pro";
        src: url(${ApercuLightProWoff2}) format("woff2"),
             url(${ApercuLightProWoff}) format("woff");
        font-weight: 300;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: "Apercu Pro";
        src: url(${ApercuRegularProWoff2}) format("woff2"),
             url(${ApercuRegularProWoff}) format("woff");
        font-weight: 400;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: "Apercu Pro";
        src: url(${ApercuMediumProWoff2}) format("woff2"),
             url(${ApercuMediumProWoff}) format("woff");
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: "Apercu Pro";
        src: url(${ApercuBoldProWoff2}) format("woff2"),
             url(${ApercuBoldProWoff}) format("woff");
        font-weight: 700;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: "Recoleta";
        src: url(${RecoletaBoldWoff2}) format("woff2"),
             url(${RecoletaBoldWoff}) format("woff");
        font-weight: 700;
        font-style: normal;
        font-display: swap;
    }

    :root {
        ${mapPalette(colorTokens.palette)}

        ${mapThemeTokens(colorTokens.theme.light)}

        --huma-color-integration-24sevenoffice: rgb(0, 0, 0);
        --huma-color-integration-fortnox: rgb(1, 56, 36);
        --huma-color-integration-nuu: rgb(37, 37, 37);
        --huma-color-integration-slack: rgb(0, 0, 0);
        --huma-color-integration-tripletex-1: rgb(88, 179, 205);
        --huma-color-integration-tripletex-2: rgb(26, 185, 109);
        --huma-color-integration-tripletex-3: rgb(1, 10, 89);
        --huma-color-integration-unimicro: rgb(0, 0, 0);
        --huma-color-integration-webhook: rgb(30, 32, 35);
        --huma-color-integration-xledger: rgb(31, 68, 151);

        --huma-color-hljs-keyword: rgb(130, 87, 58);
        --huma-color-hljs-string: rgb(94, 107, 52);

        --huma-color-shadow: rgb(0 0 0 / .1);

        --huma-shadow-lowest: 0 1px 4px var(--huma-color-shadow);
        --huma-shadow-lower: 0 4px 10px var(--huma-color-shadow);
        --huma-shadow-low: 0 6px 16px var(--huma-color-shadow);
        --huma-shadow-high: 0 8px 20px var(--huma-color-shadow);
        --huma-shadow-higher: 0 10px 24px var(--huma-color-shadow);
        --huma-shadow-highest: 0 12px 32px var(--huma-color-shadow);

        --huma-select-arrow-url: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICAgIDxwYXRoIGQ9Ik0xMS4xNzgsMTkuNTY5QzExLjM2NCwxOS44MzksMTEuNjcyLDIwLDEyLDIwczAuNjM2LTAuMTYxLDAuODIyLTAuNDMxbDktMTNjMC4yMTItMC4zMDYsMC4yMzYtMC43MDQsMC4wNjMtMS4wMzMgQzIxLjcxMyw1LjIwNywyMS4zNzIsNSwyMSw1SDNDMi42MjgsNSwyLjI4Nyw1LjIwNywyLjExNCw1LjUzNkMxLjk0MSw1Ljg2NSwxLjk2Niw2LjI2MywyLjE3OCw2LjU2OUwxMS4xNzgsMTkuNTY5eiIgZmlsbD0iIzE2MTUxMyIvPgo8L3N2Zz4=);
        --huma-select-arrow-disabled-url: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICAgIDxwYXRoIGQ9Ik0xMS4xNzgsMTkuNTY5QzExLjM2NCwxOS44MzksMTEuNjcyLDIwLDEyLDIwczAuNjM2LTAuMTYxLDAuODIyLTAuNDMxbDktMTNjMC4yMTItMC4zMDYsMC4yMzYtMC43MDQsMC4wNjMtMS4wMzMgQzIxLjcxMyw1LjIwNywyMS4zNzIsNSwyMSw1SDNDMi42MjgsNSwyLjI4Nyw1LjIwNywyLjExNCw1LjUzNkMxLjk0MSw1Ljg2NSwxLjk2Niw2LjI2MywyLjE3OCw2LjU2OUwxMS4xNzgsMTkuNTY5eiIgZmlsbD0iI2JmYmViZCIvPgo8L3N2Zz4=);

        --huma-global-main-layout-width: 1520px;
        --huma-global-main-layout-header-height: 72px;
        --huma-global-main-layout-gutter: 32px;
        --huma-global-nav-width: 288px;
        --huma-global-search-width: 352px;
        --huma-global-content-padding-block-start: 72px;
        --huma-global-content-padding-block-end: 64px;
        --huma-global-content-padding-inline: 88px;
        --huma-global-content-tight-width: 656px;
        --huma-global-sticky-menu-height: 72px;

        ${belowDesktop`
            --huma-global-content-padding-block-start: 24px;
            --huma-global-content-padding-block-end: 96px;
        `}

        ${bp.between('laptop', 'desktop')`
            --huma-global-content-padding-inline: 48px;
        `}

        ${belowLaptop`
            --huma-global-main-layout-header-height: 56px;
            --huma-global-main-layout-gutter: 24px;
            --huma-global-content-padding-inline: 24px;
            --huma-global-search-width: 40px;
            --huma-global-sticky-menu-height: 56px;
        `}
    }

    @media only screen {
        html[data-theme="dark"] {
            color-scheme: dark;

            ${mapThemeTokens(colorTokens.theme.dark)}

            --huma-color-integration-24sevenoffice: rgb(255, 255, 255);
            --huma-color-integration-fortnox: rgb(255, 255, 255);
            --huma-color-integration-nuu: rgb(255, 255, 255);
            --huma-color-integration-slack: rgb(255, 255, 255);
            --huma-color-integration-tripletex-1: rgb(255, 255, 255);
            --huma-color-integration-tripletex-2: rgb(255, 255, 255);
            --huma-color-integration-tripletex-3: rgb(255, 255, 255);
            --huma-color-integration-unimicro: rgb(255, 255, 255);
            --huma-color-integration-webhook: rgb(220, 220, 220);
            --huma-color-integration-xledger: rgb(255, 255, 255);

            --huma-color-hljs-keyword: rgb(225, 187, 155);
            --huma-color-hljs-string: rgb(178, 193, 141);

            --huma-select-arrow-url: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICAgIDxwYXRoIGQ9Ik0xMS4xNzgsMTkuNTY5QzExLjM2NCwxOS44MzksMTEuNjcyLDIwLDEyLDIwczAuNjM2LTAuMTYxLDAuODIyLTAuNDMxbDktMTNjMC4yMTItMC4zMDYsMC4yMzYtMC43MDQsMC4wNjMtMS4wMzMgQzIxLjcxMyw1LjIwNywyMS4zNzIsNSwyMSw1SDNDMi42MjgsNSwyLjI4Nyw1LjIwNywyLjExNCw1LjUzNkMxLjk0MSw1Ljg2NSwxLjk2Niw2LjI2MywyLjE3OCw2LjU2OUwxMS4xNzgsMTkuNTY5eiIgZmlsbD0iI2ZhZmFmOSIvPgo8L3N2Zz4=);
            --huma-select-arrow-disabled-url: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICAgIDxwYXRoIGQ9Ik0xMS4xNzgsMTkuNTY5QzExLjM2NCwxOS44MzksMTEuNjcyLDIwLDEyLDIwczAuNjM2LTAuMTYxLDAuODIyLTAuNDMxbDktMTNjMC4yMTItMC4zMDYsMC4yMzYtMC43MDQsMC4wNjMtMS4wMzMgQzIxLjcxMyw1LjIwNywyMS4zNzIsNSwyMSw1SDNDMi42MjgsNSwyLjI4Nyw1LjIwNywyLjExNCw1LjUzNkMxLjk0MSw1Ljg2NSwxLjk2Niw2LjI2MywyLjE3OCw2LjU2OUwxMS4xNzgsMTkuNTY5eiIgZmlsbD0iIzY3NjY2NSIvPgo8L3N2Zz4=);

            /* CUSTOM BY ERIK */
            --huma-color-surface-highlight: rgb(from var(--huma-palette-yellow-80) r g b / .5);
        }
    }

    *,
    ::before,
    ::after {
        box-sizing: border-box;
    }

    html {
        position: relative;
        overflow-y: auto;

        height: 100dvh;
        background-color: var(--huma-color-surface-minimal);

        &[data-authorized="no"] {
            background: var(--huma-color-surface-warning-minimal);

            &[data-theme="dark"] {
                background-color: var(--huma-color-surface-minimal);
            }
        }

        ${belowTablet`
            overflow-y: scroll;
            -webkit-overflow-scrolling: touch;
            background: var(--huma-color-surface-default);

            &[data-authorized="no"] {
                background: var(--huma-color-surface-warning-minimal);

                &[data-theme="dark"] {
                    background-color: var(--huma-color-surface-minimal);
                }
            }
        `}

        ${print`
            background-color: white;
        `}
    }

    body {
        min-height: 100svh;
        ${fontSans}
        font-size: 16px;
        line-height: 1;
        color: var(--huma-color-foreground-default);
        ${optimizeFontRendering}

        &.blocking {
            overflow: visible !important;
            margin-right: 0 !important;
        }
    }

    #main {
        display: grid;
        grid-template-rows:
            [announcements] auto
            [header-start] var(--huma-global-main-layout-header-height)
            [nav-start content-start header-end] 1fr [nav-end content-end];
        grid-template-columns:
            [full-width-start] minmax(var(--huma-global-main-layout-gutter), 1fr)
            [nav-start header-start] var(--huma-global-nav-width)
            [nav-end search-start content-start] var(--huma-global-search-width)
            [global-actions-start search-end] min(100% - var(--huma-global-nav-width) - var(--huma-global-search-width) - (var(--huma-global-main-layout-gutter) * 2), 1520px - var(--huma-global-nav-width) - var(--huma-global-search-width))
            [content-end global-actions-end header-end] minmax(var(--huma-global-main-layout-gutter), 1fr) [full-width-end];
        align-items: start;

        min-height: 100svh;
        perspective-origin: 0 50%;
    }

    #modals,
    #widget,
    #prompt {
        display: flow-root;
        width: 100vw;
        min-height: 100svh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 4;
        color: var(--huma-color-foreground-default);
        pointer-events: none;
        transform: translateZ(0);
    }

    #modals,
    #widget,
    #prompt {
        > * { pointer-events: auto; }
    }

    #modals {
        ${iOSSafari`
            height: 100vh;
        `}
    }

    #bottom-panel {
        &,
        & > * {
            display: grid;
            grid-template: subgrid / subgrid;
            grid-area: content;

            ${belowDesktop`
                grid-column-end: full-width;
            `}

            ${belowLaptop`
                grid-column: full-width;
            `}
        }
    }

    #off-screen {
        display: none;
    }

    #voiceflow-chat {
        ${print`
            body && { display: none !important; }
        `}
    }

    @layer form {
        ::placeholder {
            overflow: visible;

            color: var(--huma-color-foreground-minimal);
        }

        input,
        textarea,
        select {
            ${fontSans}
            ${optimizeFontRendering}
            color: inherit;
        }

        select[disabled] {
            opacity: 1;
        }

        fieldset {
            padding: 0;
        }
    }

    @layer button {
        button {
            ${fontSans}
            ${optimizeFontRendering}
            color: inherit;
        }

        button::-moz-focus-inner {
            border: 0;
            padding: 0;
            margin: 0;
        }
    }

    @layer reset {
        h1, h2, h3, h4, h5, h6,
        p, ul, ol, dl { margin: 0; }

        ul, ol { list-style: none; }

        em { font-style: italic; }

        strong {
            font-weight: 700;

            &.subtle {
                font-weight: 500;
            }
        }
    }

    svg {
        display: flex;

        &.rough-annotation {
            z-index: -1;
        }
    }

    ::view-transition-old(page) {
        animation:
            60ms cubic-bezier(0.4, 0, 1, 1) both ${fadeOut},
            200ms cubic-bezier(0.4, 0, 0.2, 1) both ${slideToStart};
    }

    ::view-transition-new(page) {
        animation:
            160ms cubic-bezier(0, 0, 0.2, 1) both ${fadeIn},
            200ms cubic-bezier(0.4, 0, 0.2, 1) both ${slideFromEnd};
    }

    .back-transition::view-transition-old(page) {
        animation-name: ${fadeOut}, ${slideToEnd};
    }

    .back-transition::view-transition-new(page) {
        animation-name: ${fadeIn}, ${slideFromStart};
    }

    --contrast-boost: "prefers-contrast: more";
`

const tippyStyles = css`
    [data-tippy-root] {
        max-width: calc(100vw - 16px);

        &:has(> .widget) {
            ${belowTablet`
                inset: auto auto 0 0 !important;
                overscroll-behavior: contain;

                border-width: 0;
                border-radius: 16px 16px 0 0;
                width: 100%;
                max-width: 100%;
                max-height: 80svh;
                padding: 0 24px;
                background-color: var(--huma-color-surface-elevated);
                box-shadow: var(--huma-shadow-lowest);

                transform: unset !important;

                ${({ theme }) => css`
                    padding-bottom: ${!!theme.widget?.scrollable ? '24px' : '0'};
                    overflow-y: ${!!theme.widget?.scrollable ? 'auto' : 'hidden'};
                `}
            `}
        }
    }

    .tippy-box[data-animation=scale-subtle] {
        &[data-placement^=top] {
            transform-origin: bottom;
        }

        &[data-placement^=bottom] {
            transform-origin: top;
        }

        &[data-placement^=left] {
            transform-origin: right;
        }
        &[data-placement^=right] {
            transform-origin: left;
        }

        &[data-state=hidden] {
            transform: scale(.8);
            opacity: 0;
        }
    }
`

export const GlobalStyles = createGlobalStyle`
    @layer
        reset,
        form,
        button, paragraph;

    @layer reset {
        ${normalize}
    }

    ${globalStylesCss}
    ${tippyStyles}
`

export const WhiteBackgroundGlobalStyles = createGlobalStyle`
    ${globalStylesCss}

    body {
        background: white;
    }
`